var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Table',{attrs:{"id":"runsTable","loading":_vm.isLoading,"params":_vm.aParams,"selected":_vm.run,"apiUrl":"driver_runs","paginated":""},on:{"update:loading":function($event){_vm.isLoading=$event},"select":_vm.onSelect}},[_c('b-table-column',{attrs:{"label":_vm.$t('global.driver')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:row.is_active ? '' : 'inactive'},[_vm._v(" "+_vm._s(row.driver_name)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('week.sun.short'),"width":"30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:row.is_active ? '' : 'inactive'},[_c('DayCheckStatus',{attrs:{"dayNumber":6,"days":row.days}})],1)]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('week.mon.short'),"width":"30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:row.is_active ? '' : 'inactive'},[_c('DayCheckStatus',{attrs:{"dayNumber":0,"days":row.days}})],1)]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('week.tue.short'),"width":"30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:row.is_active ? '' : 'inactive'},[_c('DayCheckStatus',{attrs:{"dayNumber":1,"days":row.days}})],1)]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('week.wed.short'),"width":"30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:row.is_active ? '' : 'inactive'},[_c('DayCheckStatus',{attrs:{"dayNumber":2,"days":row.days}})],1)]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('week.thu.short'),"width":"30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:row.is_active ? '' : 'inactive'},[_c('DayCheckStatus',{attrs:{"dayNumber":3,"days":row.days}})],1)]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('week.fri.short'),"width":"30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:row.is_active ? '' : 'inactive'},[_c('DayCheckStatus',{attrs:{"dayNumber":4,"days":row.days}})],1)]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('week.sat.short'),"width":"30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:row.is_active ? '' : 'inactive'},[_c('DayCheckStatus',{attrs:{"dayNumber":5,"days":row.days}})],1)]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('runs.wHours'),"width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:("text-center " + (row.is_active ? '' : 'inactive'))},[_vm._v(" "+_vm._s(_vm.formatTime(row.from_time))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.formatTime(row.to_time))+" ")])]}}])}),_c('b-table-column',{attrs:{"label":_vm.$t('global.date'),"width":"110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:row.is_active ? '' : 'inactive'},[_vm._v(" "+_vm._s(_vm.formatDate(row.from_date))),_c('br'),_vm._v(" "+_vm._s(_vm.formatDate(row.to_date))+" ")])]}}])}),_c('b-table-column',{attrs:{"label":_vm.$tc('vehicle.label')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:row.is_active ? '' : 'inactive'},[_vm._v(" "+_vm._s(row.vehicle_name)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":_vm.$tc('vehicle.garage')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{class:row.is_active ? '' : 'inactive'},[(row['pull_in_garage.full_address'])?_c('div',{staticClass:"flex"},[_c('b-icon',{staticClass:"carIcon",attrs:{"custom-class":"mdi-flip-h","icon":"van-utility"}}),_vm._v(" "+_vm._s(row['pull_in_garage.full_address'] || '-')+" ")],1):_c('div',[_vm._v("-")]),(row['pull_out_garage.full_address'])?_c('div',{staticClass:"flex"},[_c('b-icon',{staticClass:"carIcon",attrs:{"icon":"van-utility"}}),_vm._v(" "+_vm._s(row['pull_out_garage.full_address'] || '-')+" ")],1):_c('div',[_vm._v("-")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }