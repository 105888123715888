<template>
  <Table
    id="runsTable"
    :loading.sync="isLoading"
    :params="aParams"
    :selected="run"
    @select="onSelect"
    apiUrl="driver_runs"
    paginated
  >
    <b-table-column :label="$t('global.driver')" v-slot="{ row }">
      <div :class="row.is_active ? '' : 'inactive'">
        {{ row.driver_name }}
      </div>
    </b-table-column>

    <b-table-column :label="$t('week.sun.short')" width="30" v-slot="{ row }">
      <div :class="row.is_active ? '' : 'inactive'">
        <DayCheckStatus :dayNumber="6" :days="row.days" />
      </div>
    </b-table-column>
    <b-table-column :label="$t('week.mon.short')" width="30" v-slot="{ row }">
      <div :class="row.is_active ? '' : 'inactive'">
        <DayCheckStatus :dayNumber="0" :days="row.days" />
      </div>
    </b-table-column>
    <b-table-column :label="$t('week.tue.short')" width="30" v-slot="{ row }">
      <div :class="row.is_active ? '' : 'inactive'">
        <DayCheckStatus :dayNumber="1" :days="row.days" />
      </div>
    </b-table-column>
    <b-table-column :label="$t('week.wed.short')" width="30" v-slot="{ row }">
      <div :class="row.is_active ? '' : 'inactive'">
        <DayCheckStatus :dayNumber="2" :days="row.days" />
      </div>
    </b-table-column>
    <b-table-column :label="$t('week.thu.short')" width="30" v-slot="{ row }">
      <div :class="row.is_active ? '' : 'inactive'">
        <DayCheckStatus :dayNumber="3" :days="row.days" />
      </div>
    </b-table-column>
    <b-table-column :label="$t('week.fri.short')" width="30" v-slot="{ row }">
      <div :class="row.is_active ? '' : 'inactive'">
        <DayCheckStatus :dayNumber="4" :days="row.days" />
      </div>
    </b-table-column>
    <b-table-column :label="$t('week.sat.short')" width="30" v-slot="{ row }">
      <div :class="row.is_active ? '' : 'inactive'">
        <DayCheckStatus :dayNumber="5" :days="row.days" />
      </div>
    </b-table-column>
    <b-table-column :label="$t('runs.wHours')" width="130" v-slot="{ row }">
      <div :class="`text-center ${row.is_active ? '' : 'inactive'}`">
        {{ formatTime(row.from_time) }} <br />
        {{ formatTime(row.to_time) }}
      </div>
    </b-table-column>
    <b-table-column :label="$t('global.date')" width="110" v-slot="{ row }">
      <div :class="row.is_active ? '' : 'inactive'">
        {{ formatDate(row.from_date) }}<br />
        {{ formatDate(row.to_date) }}
      </div>
    </b-table-column>
    <b-table-column :label="$tc('vehicle.label')" v-slot="{ row }">
      <div :class="row.is_active ? '' : 'inactive'">
        {{ row.vehicle_name }}
      </div>
    </b-table-column>
    <b-table-column :label="$tc('vehicle.garage')" v-slot="{ row }">
      <div :class="row.is_active ? '' : 'inactive'">
        <div v-if="row['pull_in_garage.full_address']" class="flex">
          <b-icon custom-class="mdi-flip-h" class="carIcon" icon="van-utility" />
          {{ row['pull_in_garage.full_address'] || '-' }}
        </div>
        <div v-else>-</div>
        <div v-if="row['pull_out_garage.full_address']" class="flex">
          <b-icon class="carIcon" icon="van-utility" />
          {{ row['pull_out_garage.full_address'] || '-' }}
        </div>
        <div v-else>-</div>
      </div>
    </b-table-column>
  </Table>
</template>

<script>
import DayCheckStatus from './DayCheckStatus.vue';
import Table from '@/components/Table';
export default {
  components: {
    DayCheckStatus,
    Table
  },
  data() {
    return {
      isLoading: true,
      run: {},
      showInactive: true
    };
  },
  computed: {
    aParams() {
      const aParams = [];
      Object.keys(this.params).map((key) => {
        const value = this.params[key];
        if (key === 'searchParams') {
          if (value.length) value.map((v) => aParams.push(v));
        } else if (key === 'showInactive') {
          if (!value) aParams.push(`driver_run.is_active=1`);
        } else if (value) aParams.push(`${key}=${value}`);
      });
      return aParams;
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return '-';
      return this.$moment(date).format('MM/DD/Y');
    },
    formatTime(time) {
      if (!time) return '-';
      const utcDate = this.$moment.utc(time, 'HH:mm:ss').toDate();
      return this.$moment(utcDate).format('hh:mm A');
    },
    onSelect(selectedRun) {
      this.run = selectedRun;
      this.$emit('select', selectedRun);
    }
  },
  watch: {
    loading(value) {
      this.isLoading = value;
    },
    isLoading(value) {
      this.$emit('update:loading', value);
    }
  },
  props: {
    loading: { type: Boolean, default: false },
    params: {
      type: Object,
      default: () => {}
    }
  }
};
</script>
<style lang="sass" scoped>
#runsTable
  // @include scroll-bar-table
  ::v-deep
    .table-wrapper
      height: calc(100vh - 350px)
    @for $i from 2 through 8
      th:nth-child(#{$i}),
      td:nth-child(#{$i})
        padding: 10px 5px!important
.base-table
  .carIcon
    color: $icon-gray
    margin-right: 5px
</style>
<style lang="sass" scoped>
.dark
  #runsTable
    ::v-deep
      .table-wrapper
        .table
          .is-selected
            .carIcon
              color: #fff
</style>
