<template>
  <section>
    <portal to="headerActions">
      <Link to="/data/runs/add" isRouter :permission="Perm.create">
        <b-button
          type="is-primary"
          :label="$t('button.add', [$tc('runs.label').toLowerCase()])"
          icon-left="plus"
        />
      </Link>
    </portal>
    <Card id="runs" @select="(selectedRun) => (run = selectedRun)" />
  </section>
</template>
<script>
import Card from '@/components/Runs/Card.vue';
import { Link } from '@/components';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    Card,
    Link
  },
  mounted() {},
  data() {
    return {
      run: {}
    };
  },
  computed: {
    Perm() {
      return Permissions.Runs;
    }
  }
};
</script>
<style lang="sass" scoped>
#runs
  margin: 0
</style>
