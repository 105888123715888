<template>
  <b-icon v-if="dayIcon" icon="check-circle-outline" class="dcs-success" />
  <b-icon v-else icon="close-circle-outline" class="dcs-danger" />
</template>

<script>
export default {
  computed: {
    dayIcon() {
      const oData = JSON.parse(this.days || '[]');
      if (oData.length && oData.filter((num) => this.dayNumber === num).length > 0) return true;
      else return false;
    }
  },
  props: {
    days: { type: String, default: '[]' },
    dayNumber: { type: Number, default: 0 }
  }
};
</script>

<style lang="sass" scoped>
.dcs-success
  color: $success
.dcs-danger
  color: $danger
</style>
