<template>
  <div class="filters flex f-ai">
    <SearchField
      api="vehicles"
      queryKey="driver_run.name"
      :searchParams.sync="searchParams"
      :placeholder="$t('runs.search.title')"
      :disabled="disabled"
      :filterOptions="{
        label: $t('vehicle.name'),
        api: 'vehicles?per_page=9999&is_active=1',
        data: [],
        field: 'name',
        model: 'vehicle_id'
      }"
    />
    <Autocomplete
      :label="$t('vehicle.group')"
      :disabled="disabled"
      :api="{
        url: 'vehicle_groups',
        full: true,
        params: [{ id: 'is_active', value: 1 }]
      }"
      @select="
        (data) => {
          params.vehicle_group_id = data && data.id;
        }
      "
      field="name"
      clearable
    />
    <ISwitch
      v-model="params.showInactive"
      :text="$t('vehicles.showInactive')"
      :disabled="disabled"
      :permission="Permission.setDefault"
    />
  </div>
</template>

<script>
import Autocomplete from '@/components/Autocomplete.vue';
import ISwitch from '@/components/iSwitch.vue';
// import Field from '@/components/Field.vue';
import SearchField from '@/components/SearchField.vue';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    // Field,
    Autocomplete,
    ISwitch,
    SearchField
  },
  data() {
    return {
      searchParams: [],
      params: { showInactive: true, vehicle_group_id: null }
    };
  },
  computed: {
    Permission() {
      return Permissions.Vehicles;
    }
  },
  methods: {
    emitParams() {
      const { params, searchParams } = this;
      this.$emit('input', { ...params, searchParams });
    }
  },
  watch: {
    searchParams() {
      this.emitParams();
    },
    params: {
      deep: true,
      handler() {
        this.emitParams();
      }
    }
  },
  props: {
    disabled: { type: Boolean, default: false },
    value: { type: Object, default: () => {} }
  }
};
</script>

<style lang="sass" scoped>
.filters
  margin-bottom: 20px
  ::v-deep
    div label
      color: $gray-700 !important
.iSwitch
  margin-top: 25px
  min-width: fit-content!important
  width: fit-content!important
.search-field
  min-width: 400px
  max-width: 600px
  width: 100%
.autocomplete
  max-width: 300px
.field:not(.iSwitch),
.search-field
  width: 100%
  margin-right: 20px
  margin-bottom: 0!important
  align-self: self-start
  ::v-deep
    .label
      height: 24px
</style>
