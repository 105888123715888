var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filters flex f-ai"},[_c('SearchField',{attrs:{"api":"vehicles","queryKey":"driver_run.name","searchParams":_vm.searchParams,"placeholder":_vm.$t('runs.search.title'),"disabled":_vm.disabled,"filterOptions":{
      label: _vm.$t('vehicle.name'),
      api: 'vehicles?per_page=9999&is_active=1',
      data: [],
      field: 'name',
      model: 'vehicle_id'
    }},on:{"update:searchParams":function($event){_vm.searchParams=$event},"update:search-params":function($event){_vm.searchParams=$event}}}),_c('Autocomplete',{attrs:{"label":_vm.$t('vehicle.group'),"disabled":_vm.disabled,"api":{
      url: 'vehicle_groups',
      full: true,
      params: [{ id: 'is_active', value: 1 }]
    },"field":"name","clearable":""},on:{"select":function (data) {
        _vm.params.vehicle_group_id = data && data.id;
      }}}),_c('ISwitch',{attrs:{"text":_vm.$t('vehicles.showInactive'),"disabled":_vm.disabled,"permission":_vm.Permission.setDefault},model:{value:(_vm.params.showInactive),callback:function ($$v) {_vm.$set(_vm.params, "showInactive", $$v)},expression:"params.showInactive"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }