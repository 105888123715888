<template>
  <Card>
    <Filters v-model="filterParams" />
    <MainTable @select="onSelect" :params="params" />
    <!-- @loading="(val) => (loading.table = val)" -->
    <template #footer>
      <div class="flex f-full f-ai f-jsb">
        <ISwitch
          :value="run.is_active"
          :text="$tc('switch.active', run.is_active ? 1 : 2)"
          @input="onToggleActive"
          :disabled="!run.id || loading.toggleActive"
          :loading="loading.toggleActive"
          :permission="Permission.setDefault"
        />
        <div class="buttons">
          <Button
            type="is-primary"
            @click="onEdit"
            :label="$t('button.edit')"
            :disabled="!run.id"
            :permission="Permission.update"
          />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/Card.vue';
import Filters from './Filters.vue';
import ISwitch from '@/components/iSwitch.vue';
import MainTable from './MainTable.vue';
import { Button } from '@/components';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    Button,
    Card,
    Filters,
    ISwitch,
    MainTable
  },
  data() {
    return {
      showInactive: true,
      loading: { toggleActive: false, data: false },
      filterParams: {},
      run: {}
    };
  },
  mounted() {},
  computed: {
    params() {
      return this.filterParams;
    },
    Permission() {
      return Permissions.Runs;
    }
  },
  methods: {
    onEdit() {
      this.$router.push({ path: `/data/runs/${this.run.id}` });
    },
    onSelect(selectedRun) {
      this.run = selectedRun;
      this.$emit('select', selectedRun);
    },
    async onToggleActive(is_active) {
      this.loading.toggleActive = true;
      try {
        await this.Api.patch(`driver_runs/${this.run.id}`, { is_active });
        this.run.is_active = is_active;
        this.isDisabled = !is_active;
      } catch (error) {
        const value = this.run.is_active;
        this.run.is_active = !value;
        setTimeout(() => {
          this.run.is_active = value;
        }, 100);
        console.error(error);
      }
      this.loading.toggleActive = false;
    }
  }
};
</script>

<style lang="sass" scoped>
.card
  height: calc(100% - 100px)!important
  ::v-deep
    .card-content
      display: flex
      flex-flow: column
      height: calc(100% - 60px)!important
</style>
